import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FileGateway } from '../gateways';
import { FetchedEntities, File } from '../types';
import { validateFileName } from '../validations';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, fileKeyFactory } from '@/core/query-keys';
import { omit } from 'lodash';

interface MutationProps {
  id: string;
  name: string;
  folder_id?: string;
}

async function updateFile({ ...props }: MutationProps) {
  return handleResponse(FileGateway.update(omit(props, 'folder_id')));
}

export function useRenameFile() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string | undefined) => (
    <SuccessNotification
      title="Updated"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        File <b>{name}</b> was updated successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(updateFile, {
    onMutate: ({ name, folder_id }) => {
      const queryData = queryClient.getQueryData<FetchedEntities>(
        entityKeyFactory.byEntityId(folder_id)
      );
      const fileNames = queryData?.files.map((file: File) => file.name);
      validateFileName(name, fileNames);
    },
    onSuccess: ({ id, name }) => {
      queryClient.invalidateQueries(fileKeyFactory.byFileId(id));
      queryClient.invalidateQueries(fileKeyFactory.stats());
      queryClient.invalidateQueries(entityKeyFactory.all);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(name) }
      });
    }
  });
}
