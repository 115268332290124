import { EntityType, FetchedEntities, MoveEntity } from '@/core/types';

export function checkDestinationDuplication(
  entities: MoveEntity[] = [],
  existingEntities?: FetchedEntities,
  parent_id?: string
) {
  const fileNames = existingEntities?.files.map((file) => file.name);
  const folderNames = existingEntities?.folders.map((folder) => folder.name);

  const duplicates = entities.filter(
    (entity) =>
      (entity.kind === EntityType.FOLDER && folderNames?.includes(entity.name)) ||
      (entity.kind === EntityType.FILE && fileNames?.includes(entity.name))
  );

  return { entities: duplicates, parent_id };
}
