import { useQuery } from '@tanstack/react-query';
import { FolderGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { folderKeyFactory } from '@/core/query-keys';

interface QueryProps {
  id: string;
}

async function fetchFolderStats({ id }: QueryProps) {
  return handleResponse(FolderGateway.getFolderStats({ id }));
}

export function useFetchFolderStats({ id }: QueryProps) {
  return useQuery(folderKeyFactory.folderStats(id), () => fetchFolderStats({ id }));
}
