import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { EntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { RawSearchedEntities, SearchedEntities } from '@/core/types/entity';
import { SearchFilters } from '@/core/types';
import { buildFileEntity, buildFolderEntity } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

interface QueryProps {
  filters: SearchFilters;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawSearchedEntities, unknown, SearchedEntities>;
}

async function searchEntities({ filters }: QueryProps) {
  return handleResponse(EntityGateway.search({ filters }));
}

export function useSearchEntities({ filters, options = {} }: HookProps) {
  const { data: user } = useCurrentUser();

  return useQuery<RawSearchedEntities, unknown, SearchedEntities>(
    entityKeyFactory.withSearch(filters),
    () => searchEntities({ filters }),
    {
      select: ({ folders, files }) => ({
        files: files.map((file) => buildFileEntity(file, user?.userId)),
        folders: folders.map((folder) => buildFolderEntity(folder, user?.userId)),
        folder_contacts: []
      }),
      ...options
    }
  );
}
