import { useQuery, useQueryClient } from '@tanstack/react-query';

import { FileGateway } from '../gateways';
import { fileKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';

interface QueryProps {
  id: string;
  print?: boolean;
}

type HookProps = QueryProps;

async function printFile({ id }: QueryProps) {
  return handleResponse(FileGateway.print({ id }), { returnType: 'blob' });
}

export function usePrintFile({ id }: HookProps) {
  const queryClient = useQueryClient();

  return useQuery(fileKeyFactory.print(id), () => printFile({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(fileKeyFactory.byFileId(id));
    },
    enabled: false
  });
}
