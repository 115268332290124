import { useBulkRestore } from '@/core/hooks';
import { NotificationActionType } from '@/core/reducers/action-types';
import { pluralizer } from '@/core/utils';
import { useNotificationContext } from '@/core/context';
import ArchiveNotification from '@/components/notifications/archive';

interface Props {
  folder_ids: string[];
  file_ids: string[];
}

export default function BulkArchiveNotification({ folder_ids, file_ids }: Props) {
  const restoreMutation = useBulkRestore();
  const totalCount = folder_ids.length + file_ids.length;
  const { dispatch } = useNotificationContext();

  const onUndo = (folder_ids: string[], file_ids: string[]) => {
    restoreMutation.mutate({ folder_ids, file_ids });
  };

  return (
    <ArchiveNotification
      title="Archived"
      onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
      onUndo={() => onUndo(folder_ids, file_ids)}
    >
      <p>
        Archived {totalCount} {pluralizer(totalCount, 'item')}
      </p>
    </ArchiveNotification>
  );
}
