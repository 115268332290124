import { useQuery } from '@tanstack/react-query';

import { userStorage } from '@/core/storage';
import { AuthGateway, HistoryGateway } from '../gateways';
import { userKeyFactory } from '@/core/query-keys';

interface QueryProps {
  token: string | string[];
}

type HookProps = QueryProps;

async function login({ token }: QueryProps) {
  const response = await AuthGateway.login({ token });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not login.');
  }
}

export function useLogin({ token }: HookProps) {
  return useQuery(userKeyFactory.login, async () => login({ token }), {
    onError: () => {
      window.location.href = process.env.NEXT_PUBLIC_ACCOUNTS_URL as string;
    },
    onSuccess: async () => {
      await Promise.all([userStorage.set({ lastTimeStamp: Date.now() }), HistoryGateway.login()]);
    }
  });
}
