import { useMutation, useQueryClient } from '@tanstack/react-query';

import SuccessNotification from '@/components/notifications/success';
import { userKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';
import { PrivateGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';

interface MutationProps {
  id: string;
}

async function enableUser({ id }: MutationProps) {
  return handleResponse(PrivateGateway.updateUser(id, { status: 'active' }));
}

export function useEnableUser() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const enableNotification = () => {
    return (
      <SuccessNotification
        title="Reactivated"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
      >
        <p>User Reactivated successfully.</p>
      </SuccessNotification>
    );
  };

  return useMutation(enableUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.all);
      queryClient.invalidateQueries(userKeyFactory.currentUser);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: enableNotification() }
      });
    }
  });
}
