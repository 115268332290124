import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PermissionGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function deletePermission({ id }: MutationProps) {
  return handleResponse(PermissionGateway.destroy({ id }));
}

export function useDeletePermission() {
  const client = useQueryClient();

  return useMutation(deletePermission, {
    onSuccess: ({ folder_id }) => {
      client.invalidateQueries(entityKeyFactory.all);
      client.invalidateQueries(folderKeyFactory.byFolderId(folder_id));
    }
  });
}
