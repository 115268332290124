import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userKeyFactory } from '@/core/query-keys';
import { useEnableUser } from '@/core/hooks/index';
import { handleResponse } from '@/core/utils';
import DeleteNotification from '@/components/notifications/delete';
import { PrivateGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';

interface MutationProps {
  id: string;
}

async function disableUser({ id }: MutationProps) {
  return handleResponse(PrivateGateway.updateUser(id, { status: 'disabled' }));
}

export function useDisableUser() {
  const queryClient = useQueryClient();
  const undoMutation = useEnableUser();
  const { dispatch } = useNotificationContext();

  const undoDisableUser = (id) => {
    undoMutation.mutate({ id });
  };

  const disableNotification = (id: string) => {
    return (
      <DeleteNotification
        title="Deactivated"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onUndo={() => undoDisableUser(id)}
      >
        <p>User was deactivated successfully.</p>
      </DeleteNotification>
    );
  };

  return useMutation(disableUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(userKeyFactory.all);
      queryClient.invalidateQueries(userKeyFactory.currentUser);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: disableNotification(data.id) }
      });
    }
  });
}
