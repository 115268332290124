import { useQuery, useQueryClient } from '@tanstack/react-query';

import { FileGateway } from '../gateways';
import { fileKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';

interface QueryProps {
  id: string;
}

type HookProps = QueryProps;

async function previewFile({ id }: QueryProps) {
  return handleResponse(FileGateway.preview({ id }), { returnType: 'blob' });
}

export function usePreviewFile({ id }: HookProps) {
  const queryClient = useQueryClient();

  return useQuery(fileKeyFactory.preview(id), () => previewFile({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(fileKeyFactory.byFileId(id));
    }
  });
}
