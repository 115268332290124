import { useQuery } from '@tanstack/react-query';

import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';
import { getDatabaseUsers } from '@/core/gateways/private';
import { DatabaseUser } from '@/core/types';

async function fetchUsers() {
  return handleResponse(getDatabaseUsers());
}

export function useFetchUsers() {
  return useQuery<DatabaseUser[], Error, DatabaseUser[]>(userKeyFactory.all, () => fetchUsers());
}
