import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';
import { pluralizer } from '@/core/utils';
import { useNotificationContext } from '@/core/context';

interface Props {
  folder_ids: string[];
  file_ids: string[];
}

export default function BulkRestoreNotification({ folder_ids, file_ids }: Props) {
  const { dispatch } = useNotificationContext();

  const totalCount = folder_ids.length + file_ids.length;
  return (
    <SuccessNotification
      title="Restored"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Restored {totalCount} {pluralizer(totalCount, 'item')}
      </p>
    </SuccessNotification>
  );
}
