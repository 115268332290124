import React, { useCallback, useEffect, useState } from 'react';
import {
  ColumnDef,
  Row,
  getSortedRowModel,
  getCoreRowModel,
  flexRender,
  useReactTable
} from '@tanstack/react-table';

import { EntityRow } from '@/core/types';
import { classNames } from '@/core/utils';
import EmptyTableRow from '@/components/table/empty-table-row';
import { renderSortIcon } from '@/components/table/sorters';
import TableRow from '@/components/table/table-row';
import { useUiContext } from '@/core/context';

interface Props<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
  rowSelectEnabled?: boolean;
  enableSorting?: boolean;
}

export function useTable<T>({
  data,
  columns,
  rowSelectEnabled = true,
  enableSorting = true
}: Props<T>) {
  const [rowData, setRowData] = useState(data);
  const [rowSelection, setRowSelection] = useState({});
  const { closeEntityPanel, selectedEntity } = useUiContext();

  const table = useReactTable<T>({
    columns: columns,
    data: rowData,
    state: {
      rowSelection
    },
    getSortedRowModel: getSortedRowModel<T>(),
    getCoreRowModel: getCoreRowModel<T>(),
    enableSorting,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection
  });

  const resetSelectedRows = () => {
    table.resetRowSelection();
  };

  const updateData = useCallback(
    (newData: T[]) => {
      if (!newData.find((data: any) => data.id === selectedEntity?.id)) {
        closeEntityPanel();
      }
      resetSelectedRows();
      setRowData(newData);
    },
    [data]
  );

  const renderHeaders = () => {
    return table.getHeaderGroups().map((headerGroup) => (
      <div key={`header-row-${headerGroup.id}`} className="table-row">
        {headerGroup.headers.map((header) => (
          <div
            key={`header-column-${header.id}`}
            className={classNames(
              header.column.columnDef.meta?.width || '',
              header.column.columnDef.meta?.responsiveDisplay || '',
              header.column.getCanSort() ? 'cursor-pointer' : '',
              'body-01 text-02 table-cell px-2 py-3 align-middle'
            )}
            onClick={header.column.getToggleSortingHandler()}
          >
            <div className="flex items-center">
              {flexRender(header.column.columnDef.header, header.getContext())}
              {renderSortIcon(header.column)}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const renderRows = () => {
    const { rows } = table.getRowModel();

    if (rows.length > 0) {
      return rows.map((row) => {
        return (
          <TableRow
            row={row as Row<EntityRow>}
            key={`table-row-${row.id}`}
            rowSelectEnabled={rowSelectEnabled}
            toggleAllRowsSelected={table.toggleAllRowsSelected}
          />
        );
      });
    } else {
      return <EmptyTableRow />;
    }
  };

  useEffect(() => {
    updateData(data);
  }, [data]);

  const Table = () => (
    <div className="table w-full border-collapse">
      <div className="table-header-group text-left border-b-2 border-ui-03">{renderHeaders()}</div>
      <div className="table-row-group body-01 text-01" data-testid={'show-table'}>
        {renderRows()}
      </div>
    </div>
  );

  return { Table, resetSelectedRows, selectedRowIds: rowSelection };
}
