import { lookup, contentType } from 'mime-types';

import { File as FileModel, FileUpload } from '../types';
import { buildEntityName, removeFileExtension, getFileExtension } from '../utils';
import { useUppyContext } from '../context';
import { useEffect, useRef } from 'react';

interface Props {
  existingFiles?: FileModel[];
}

function getMimeType(file: File) {
  return file.type === '' ? lookup(file.name) : contentType(file.type);
}

export function useUpload({ existingFiles }: Props = {}) {
  const { uppy, setInvalidFiles, setTotalFiles, cancelUpload } = useUppyContext();
  const cancelRef = useRef(cancelUpload);

  useEffect(() => {
    cancelRef.current = cancelUpload;
  }, [cancelUpload]);

  const onUpload = async (filesUpload: FileUpload[] | FileUpload) => {
    if (Array.isArray(filesUpload)) {
      let totalFiles = 0;
      for (const fileUpload of filesUpload) {
        const validFiles = fileUpload.files.filter((file) => file.name !== '.DS_Store');

        totalFiles += validFiles.length;
      }
      Object.keys(uppy.getState().currentUploads).length === 0
        ? setTotalFiles(totalFiles)
        : setTotalFiles((prev) => prev + totalFiles);

      for (const fileUpload of filesUpload) {
        !cancelRef.current && (await addFiles(fileUpload.files, fileUpload.folderId));
      }
    } else {
      Object.keys(uppy.getState().currentUploads).length === 0
        ? setTotalFiles(filesUpload.files.length)
        : setTotalFiles((prev) => prev + filesUpload.files.length);
      await addFiles(filesUpload.files, filesUpload.folderId);
    }
    await uppy.upload();
  };

  const addFiles = async (files: File[], folderId: string) => {
    for (const file of files) {
      if (cancelRef.current) break;
      if (isFileValid(file) && folderId) {
        const name = buildFileName(file);
        const extension = getFileExtension(file);

        if (extension) {
          uppy.addFile({
            source: 'file input',
            name,
            type: file.type,
            data: file,
            meta: {
              extension,
              relativePath: `${folderId}/${name}`,
              folderId
            }
          });
        } else {
          setError(file, 'File extension not found.');
        }

        const queue = uppy.getFiles().filter((file) => {
          return file.progress && !file.progress.uploadComplete;
        });
        const currentSize = queue.reduce((acc, file) => acc + file.size, 0) / 100000;

        if (currentSize >= 100 || queue.length === 200) {
          await uppy.upload();
        }
      } else if (file.size < 1) {
        setError(file, 'Invalid file. The file is blank.');
      } else {
        if (file.name === '.DS_Store') continue;
        setError(file, 'Invalid file. The type is blank or not supported.');
      }
    }
  };

  const isFileValid = (file: File) => {
    if (file.name.charAt(0) === '.') return false;
    if (file.size < 1) return false;
    return !(!getMimeType(file) && !getFileExtension(file));
  };

  const setError = (file: File, error: string) => {
    setInvalidFiles((prev) => [...prev, ...[{ file, error }]]);
  };

  const buildFileName = (file: File) => {
    const entityNames = existingFiles?.map((file: FileModel) => file.name) || [];
    return buildEntityName(removeFileExtension(file.name), entityNames);
  };

  return { onUpload };
}
