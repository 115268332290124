const entityKeys = {
  all: ['entities'],
  byEntityId: (entityId?: string | string[] | null) => [...entityKeys.all, entityId || undefined],
  archived: ['entities', 'archived'],
  filtered: ['entities', 'filtered'],
  autocomplete: ['entities', 'autocomplete'],
  search: ['entities', 'search'],
  missingIndexes: ['entities', 'missingIndexes'],
  favorites: ['entities', 'favorite'],
  shared: ['entities', 'shared'],
  favorite: (id: string) => [...entityKeys.favorites, id],
  withFilters: (filters?: Record<string, any>) => [...entityKeys.filtered, filters],
  withAutocomplete: (query: string) => [...entityKeys.autocomplete, query],
  withSearch: (filters?: Record<string, any>) => [...entityKeys.search, filters],
  withMissingIndexes: (filters?: Record<string, any>) => [...entityKeys.missingIndexes, filters]
};

export default entityKeys;
