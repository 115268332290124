import { useQuery } from '@tanstack/react-query';

import { FileStats } from '../types';
import { FileGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { fileKeyFactory } from '@/core/query-keys';

async function fileStats() {
  return handleResponse(FileGateway.fetchStats());
}

export function useFileStats() {
  return useQuery<FileStats>(fileKeyFactory.stats(), fileStats);
}
