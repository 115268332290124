import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { upperFirst } from 'lodash';

import { entityKeyFactory, fileKeyFactory, folderKeyFactory } from '@/core/query-keys';
import { EntityType, File, Folder } from '@/core/types';
import { handleResponse } from '@/core/utils';
import { FileGateway, FolderGateway } from '@/core/gateways';
import SuccessNotification from '@/components/notifications/success';
import { NotificationActionType } from '@/core/reducers/action-types';
import { useNotificationContext } from '@/core/context';

interface QueryProps {
  id: string;
  entityType: EntityType.FOLDER | EntityType.FILE;
  enabled: boolean;
  parentId?: string;
}

interface HookProps extends QueryProps {
  options?: UseMutationOptions<File | Folder>;
}

async function updateFavorite({ id, entityType, enabled }: QueryProps) {
  if (entityType === EntityType.FOLDER) {
    return handleResponse(FolderGateway.favorite({ id, enabled }));
  } else {
    return handleResponse(FileGateway.favorite({ id, enabled }));
  }
}

export function useUpdateFavorite(props: HookProps) {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const { id, parentId, entityType, enabled, options = {} } = props;

  const successNotification = (name: string) => {
    const action = enabled ? 'favorited' : 'unfavorited';
    return (
      <SuccessNotification
        title={upperFirst(action)}
        onClose={() => {
          dispatch({ type: NotificationActionType.ClosePanel });
        }}
      >
        <p>
          {upperFirst(entityType)} <b>{name}</b> was {action} successfully.
        </p>
      </SuccessNotification>
    );
  };

  return useMutation<File | Folder>(entityKeyFactory.favorite(id), () => updateFavorite(props), {
    onSuccess: () => {
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(parentId));
      queryClient.invalidateQueries(entityKeyFactory.favorites);

      if (entityType === EntityType.FOLDER) {
        queryClient.invalidateQueries(folderKeyFactory.byFolderId(id));
      } else {
        queryClient.invalidateQueries(fileKeyFactory.byFileId(id));
      }
    },
    onSettled: (data, error) => {
      if (!error && data) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: successNotification(data.name) }
        });
      }
    },
    ...options
  });
}
