import { get } from '@/core/http';
import { useInfiniteQuery } from '@tanstack/react-query';

interface PagedResponse extends Response {
  nextPage: number;
  previousPage: number;
  data: any;
}

// This is not being used yet. Added as a placeholder in case of future paged responses.
const baseUrl = 'http://localhost:3000/api/rest/history';

async function pagination({ pageParam = 1 }) {
  const response = await get({ url: `${baseUrl}?page=${pageParam}` });
  if (response.ok) {
    return response.json();
  }
}

export function usePagination() {
  return useInfiniteQuery<PagedResponse, Error>(['history'], pagination, {
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    }
  });
}
