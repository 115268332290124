import { useMutation } from '@tanstack/react-query';

import { SessionGateway } from '../gateways';
import { Database } from '../types';

interface MutationProps {
  database: Database;
}

async function switchDatabase({ database }: MutationProps) {
  const response = await SessionGateway.setCurrentDatabase({ database });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not get current user.');
  }
}

export function useSwitchDatabase() {
  return useMutation(switchDatabase, {
    onSuccess: () => {
      window.location.href = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL as string;
    }
  });
}
