import { useQuery } from '@tanstack/react-query';

import { FolderContactGateway } from '../gateways';
import { FolderContact } from '../types';
import { handleResponse } from '@/core/utils/response';
import folderContactKeys from '@/core/query-keys/folder-contact';

interface QueryProps {
  folder_id: string;
}

type HookProps = QueryProps;

async function fetchFolderContacts({ folder_id }: QueryProps) {
  return handleResponse(FolderContactGateway.byFolderId({ folder_id }));
}

export function useFetchFolderContacts({ folder_id }: HookProps) {
  return useQuery<FolderContact[], Error, FolderContact[], any>(
    folderContactKeys.byFolderId(folder_id),
    () => fetchFolderContacts({ folder_id })
  );
}
