import { useQuery } from '@tanstack/react-query';
import { partition } from 'lodash';

import { FetchedIndexFields, IndexField } from '../types';
import { IndexFieldGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { indexFieldKeyFactory } from '@/core/query-keys';

async function fetchIndexFields() {
  return handleResponse(IndexFieldGateway.fetch());
}

export function useFetchIndexFields() {
  return useQuery<IndexField[], Error, FetchedIndexFields>(
    indexFieldKeyFactory.all,
    fetchIndexFields,
    {
      select: (indexFields) => {
        const archived = partition(indexFields, (indexField) => !indexField.archived);
        return { indexFields: archived[0], archivedIndexFields: archived[1] };
      }
    }
  );
}
