import { useMutation } from '@tanstack/react-query';

import { handleResponse } from '@/core/utils';
import { PrivateGateway } from '../gateways';

interface MutationProps {
  id: string;
}

async function unlockUser({ id }: MutationProps) {
  return handleResponse(PrivateGateway.unlockUser(id));
}

export function useUnlockUser() {
  return useMutation(unlockUser);
}
