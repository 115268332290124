const fileKeys = {
  all: ['files'],
  stats: () => [...fileKeys.all, 'stats'],
  statsByType: () => [...fileKeys.all, 'statsByType'],
  byFileId: (id: string) => [...fileKeys.all, id],
  preview: (id: string) => [...fileKeys.all, 'preview', id],
  print: (id: string) => [...fileKeys.all, 'print', id],
  download: (id: string) => [...fileKeys.all, 'download', id],
  history: (id: string) => [...fileKeys.all, 'history', id]
};

export default fileKeys;
