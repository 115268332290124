import React, { ReactNode, useEffect } from 'react';
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid';

import { TextButton } from '../buttons';

interface Props {
  title: string;
  children: ReactNode;
  onClose(): void;
}

export default function ErrorNotification({ onClose, title, children }: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => onClose(), 10000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="mx-1 w-[22rem] sm:w-96 px-3.5 pt-4 pb-3.5" data-testid="error-notification">
        <div className="flex items-center gap-2.5">
          <ExclamationCircleIcon className="hidden sm:inline h-5 text-support-error" />
          <div className="header-03 flex-1">{title}</div>
          <TextButton onClick={onClose} data-testid="error-notif-close">
            <span className="sr-only">Close</span>
            <XIcon className="h-5" aria-hidden="true" />
          </TextButton>
        </div>
        <div className="mt-1 ml-4 flex pl-3.5">
          <div className="body-01 flex-1">{children}</div>
        </div>
      </div>
    </>
  );
}
