import React from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { FetchedEntities, RawFetchedEntities } from '../types';
import { EntityGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import ErrorNotification from '../../core/components/notifications/error';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { buildFolderEntity, buildFileEntity } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

interface QueryProps {
  folderId?: string | string[];
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawFetchedEntities, Error, FetchedEntities>;
}

async function fetchEntities({ folderId }: QueryProps) {
  return handleResponse(EntityGateway.fetch({ folderId }));
}

export function useFetchEntities({ folderId, options = {} }: HookProps) {
  const { dispatch } = useNotificationContext();
  const { data: user } = useCurrentUser();
  const router = useRouter();

  const errorComponent = () => (
    <ErrorNotification
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
      title="Not found"
    >
      Folder not found.
    </ErrorNotification>
  );

  const forbiddenComponent = () => (
    <ErrorNotification
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
      title="Forbidden"
    >
      You do not have access to this resource.
    </ErrorNotification>
  );

  return useQuery<RawFetchedEntities, Error, FetchedEntities>(
    entityKeyFactory.byEntityId(folderId),
    () => fetchEntities({ folderId }),
    {
      onError: (error: Error) => {
        router.push('/home');
        const component = error.message === 'Forbidden' ? forbiddenComponent() : errorComponent();
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component }
        });
      },
      select: ({ files, folders }) => ({
        files: files.map((file) => buildFileEntity(file, user?.userId)),
        folders: folders.map((folder) => buildFolderEntity(folder, user?.userId))
      }),
      enabled: !!user,
      ...options
    }
  );
}
