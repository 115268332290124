import { useMutation, useQueryClient } from '@tanstack/react-query';

import { entityKeyFactory, folderKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';
import { ColorGateway } from '@/core/gateways';
import { NotificationActionType } from '@/core/reducers/action-types';
import { useNotificationContext } from '@/core/context';
import SuccessNotification from '@/components/notifications/success';

interface MutationProps {
  folder_id: string;
  color: string;
}

async function updateColor({ folder_id, color }: MutationProps) {
  return handleResponse(ColorGateway.setColor({ folder_id, color }));
}

export function useUpdateColor() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = () => {
    return (
      <SuccessNotification
        title="Color saved"
        onClose={() => {
          dispatch({ type: NotificationActionType.ClosePanel });
        }}
      >
        <p>Folder color successfully saved.</p>
      </SuccessNotification>
    );
  };

  return useMutation<any, Error, MutationProps, unknown>(updateColor, {
    onSuccess: ({ folder_id }) => {
      queryClient.invalidateQueries(folderKeyFactory.byFolderId(folder_id));
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: (data, error) => {
      if (!error && data) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: successNotification() }
        });
      }
    }
  });
}
