import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IndexFieldGateway } from '../gateways';
import DeleteNotification from '../components/notifications/delete';
import { NotificationActionType } from '../reducers/action-types';
import { useNotificationContext } from '../context';
import { useUndoDeleteIndexField } from './';
import { handleResponse } from '@/core/utils/response';
import { indexFieldKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function deleteIndexField({ id }: MutationProps) {
  return handleResponse(IndexFieldGateway.destroy({ id }));
}

export function useDeleteIndexField() {
  const client = useQueryClient();
  const undoDeleteMutation = useUndoDeleteIndexField();
  const { dispatch } = useNotificationContext();

  const deleteNotification = (id: string, name: string) => {
    return (
      <DeleteNotification
        title="Deleted"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onUndo={() => onUndo(id)}
      >
        <p>Index field {name} was deleted.</p>
      </DeleteNotification>
    );
  };

  const onUndo = (id: string) => {
    undoDeleteMutation.mutate({ id });
    dispatch({ type: NotificationActionType.ClosePanel });
  };

  return useMutation(deleteIndexField, {
    onSuccess: ({ id, name }) => {
      client.invalidateQueries(indexFieldKeyFactory.all);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: deleteNotification(id, name) }
      });
    }
  });
}
