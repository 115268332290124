import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { FetchedEntities, RawFetchedEntities, SearchFilters } from '../types';
import { EntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { buildFileEntity, buildFolderEntity } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

interface QueryProps {
  filters: SearchFilters;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawFetchedEntities, Error, FetchedEntities>;
}

async function fetchFilteredEntities({ filters }: QueryProps) {
  return handleResponse(EntityGateway.fetchFiltered({ filters }));
}

export function useFetchFilteredEntities({ filters, options = {} }: HookProps) {
  const { data: user } = useCurrentUser();
  const trimmedFilters = { ...filters, name: filters.name ? filters.name?.trimEnd() : '' };

  return useQuery<RawFetchedEntities, Error, FetchedEntities>(
    entityKeyFactory.withFilters(trimmedFilters),
    () => fetchFilteredEntities({ filters: trimmedFilters }),
    {
      select: ({ files, folders }) => ({
        files: files.map((file) => buildFileEntity(file, user?.userId)),
        folders: folders.map((folder) => buildFolderEntity(folder, user?.userId))
      }),
      ...options
    }
  );
}
