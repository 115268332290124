import { useQueryClient, useMutation } from '@tanstack/react-query';

import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import SuccessNotification from '../components/notifications/success';
import { FolderGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, fileKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
  updated_at: string;
}

async function restoreFolder({ ...props }: MutationProps) {
  return handleResponse(FolderGateway.restore(props));
}

export function useRestoreFolder() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Restored"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Folder {name} was restored successfully </p>
    </SuccessNotification>
  );

  return useMutation(restoreFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(fileKeyFactory.stats());
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: ({ name }, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: successNotification(name) }
        });
      }
    }
  });
}
