import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FolderGateway } from '../gateways';
import { validateFolderName } from '../validations';
import { useNotificationContext, useUiContext, useUppyContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import SuccessNotification from '../components/notifications/success';
import { handleResponse } from '@/core/utils/response';
import { Folder } from '@/core/types';
import { entityKeyFactory } from '@/core/query-keys';

interface MutationProps {
  name: string;
  parent_id?: string;
}

async function createFolder({ ...props }: MutationProps) {
  return handleResponse(FolderGateway.create(props));
}

export function useCreateFolder(notification = true) {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();
  const { setCurrentFolder } = useUppyContext();
  const { setIsUploadPanelOpen } = useUiContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Created"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Folder <b>{name}</b> was created successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation<Folder, Error, MutationProps, unknown>(createFolder, {
    onMutate: ({ name }) => {
      validateFolderName(name);
    },
    onSuccess: ({ parent_id, name }) => {
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(parent_id));
      if (!notification) {
        setCurrentFolder(name);
        return;
      }
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(name) }
      });
    },
    onError: () => {
      setIsUploadPanelOpen(false);
    }
  });
}
