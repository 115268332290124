import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IndexFieldGateway } from '../gateways';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { indexFieldKeyFactory } from '@/core/query-keys';

interface MutationProps {
  name: string;
  description?: string;
  type: string;
  values?: Record<string, any>;
}

async function createIndexField({ ...props }: MutationProps) {
  return handleResponse(IndexFieldGateway.create(props));
}

export function useCreateIndexField() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Created"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Index field <b>{name}</b> was created.
      </p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(createIndexField, {
    onSuccess: ({ name }) => {
      client.invalidateQueries(indexFieldKeyFactory.all);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(name) }
      });
    }
  });
}
