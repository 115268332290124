import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppliedIndexGateway } from '../gateways';
import { validateIndexValue } from '../validations';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { appliedIndexKeyFactory, fileKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
  value: string;
}

const updateAppliedIndex = async ({ id, value }: MutationProps) => {
  return handleResponse(AppliedIndexGateway.update({ id, value }));
};

export function useUpdateAppliedIndex() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Updated"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Index {name} was updated successfully.</p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(updateAppliedIndex, {
    onMutate: ({ value }) => {
      validateIndexValue(value);
    },
    onSuccess: ({ entity_id, index_field }) => {
      client.invalidateQueries(appliedIndexKeyFactory.allByEntity(entity_id));
      client.invalidateQueries(fileKeyFactory.byFileId(entity_id));
      client.invalidateQueries(folderKeyFactory.byFolderId(entity_id));
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(index_field.name) }
      });
    }
  });
}
