import { useInfiniteQuery } from '@tanstack/react-query';

import { HistoryItem } from '../types';
import { HistoryGateway } from '../gateways';
import { buildHistoryFilters, handleResponse } from '@/core/utils';
import { historyKeyFactory } from '@/core/query-keys';

interface FilteredHistory extends Response {
  data: HistoryItem[];
  nextPage: number;
  previousPage: number;
}

interface Filters {
  actions: string[];
  users: number[];
  date?: { start: string; end: string };
}

async function fetchFilteredHistory({ pageParam, filters }) {
  const payload = buildHistoryFilters(filters);
  return handleResponse(HistoryGateway.fetch(pageParam, payload));
}

export function useFetchHistoryLog(filters: Filters) {
  return useInfiniteQuery<FilteredHistory, Error>(
    historyKeyFactory.filtered(filters),
    ({ pageParam = 1 }) => fetchFilteredHistory({ pageParam, filters }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      }
    }
  );
}
