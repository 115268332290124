import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export function useDebounce(func: (...any) => void, delay: number) {
  const inputsRef = useRef({ func, delay });

  useEffect(() => {
    inputsRef.current = { func, delay };
  }, [func, delay]);

  return useCallback(
    debounce((...args) => {
      if (inputsRef.current.delay === delay) {
        func(...args);
      }
    }, delay),
    [delay, debounce]
  );
}
