import { useMutation, useQueryClient } from '@tanstack/react-query';

import { handleResponse } from '@/core/utils';
import { PrivateGateway } from '@/core/gateways';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import { userKeyFactory } from '@/core/query-keys';
import { UpdateUser } from '@/core/types/user';
import SuccessNotification from '@/components/notifications/success';

interface MutationProps {
  id: string;
  userRightsId: string;
  user: UpdateUser;
  didAdminChange?: boolean;
}

async function updateUser({ id, user, userRightsId, didAdminChange }: MutationProps) {
  const response = await PrivateGateway.updateUser(id, user);
  const json = await response.json();

  if (!response.ok) {
    throw new Error('Failed to update user');
  }

  if (didAdminChange) {
    await setRole(userRightsId, user);
  }

  return json;
}

async function setRole(userRightsId: string, user: UpdateUser) {
  return handleResponse(PrivateGateway.setUserRole(userRightsId, { admin: user.admin! }));
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (user: any) => {
    return (
      <SuccessNotification
        title="Updated"
        onClose={() => {
          dispatch({ type: NotificationActionType.ClosePanel });
        }}
      >
        <p>
          Member{' '}
          <b>
            {user.firstName} {user.lastName}
          </b>{' '}
          was updated successfully.
        </p>
      </SuccessNotification>
    );
  };

  return useMutation(updateUser, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(userKeyFactory.all);
      queryClient.invalidateQueries(userKeyFactory.currentUser);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(data) }
      });
    }
  });
}
