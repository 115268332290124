import { useEffect, useRef } from 'react';
import type { DragEvent } from 'react';

import { useUpload, useCreateFolder } from '@/core/hooks';
import { File as FileModel, FolderMap } from '@/core/types';
import { useUiContext, useUppyContext } from '../context';

interface Props {
  existingFiles?: FileModel[];
}

export const useDndBulkUpload = ({ existingFiles }: Props) => {
  const { onUpload } = useUpload({ existingFiles });
  const { mutateAsync } = useCreateFolder(false);
  const { setIsUploadPanelOpen } = useUiContext();
  const { uppy, resetContext, setCurrentFolder, cancelUpload } = useUppyContext();
  const cancelRef = useRef(cancelUpload);

  useEffect(() => {
    cancelRef.current = cancelUpload;
  }, [cancelUpload]);

  const processBulkUpload = async (e: DragEvent, folderId?: string) => {
    const items = e.dataTransfer.items;
    const webkitItems: FileSystemEntry[] = [];

    e.preventDefault();

    for (const item of items) {
      const webkitItem = item.webkitGetAsEntry();

      if (webkitItem) {
        webkitItems.push(webkitItem);
      }
    }

    await processUploads(webkitItems, folderId!);
  };

  const processUploads = async (webkitItems: FileSystemEntry[], folderId: string) => {
    const folderMap: FolderMap = {};

    if (Object.keys(uppy.getState().currentUploads).length === 0) {
      resetContext();
      cancelRef.current = false;
    }

    setIsUploadPanelOpen(true);

    for (const item of webkitItems) {
      if (!cancelRef.current) {
        await scanFiles(item, folderMap, folderId);
      } else {
        break;
      }
    }

    setCurrentFolder(undefined);
    await uploadFiles(folderMap);
  };

  const scanFiles = async (item: FileSystemEntry, folderMap: FolderMap, folderId?: string) => {
    if (item.isDirectory) {
      const nextFolderId = await createDirectory(item.name, folderId);
      folderMap[nextFolderId] = { folderId: nextFolderId, files: [] };

      await readDirectory(item as FileSystemDirectoryEntry, folderMap, nextFolderId);
    } else {
      const file = await getFile(item as FileSystemFileEntry);

      if (folderId && folderMap[folderId]) {
        folderMap[folderId].files.push(file);
      } else if (folderId) {
        folderMap[folderId] = { folderId, files: [file] };
      }
    }
  };

  const createDirectory = async (name: string, parent_id?: string) => {
    const response = await mutateAsync({ name, parent_id });
    return response.id;
  };

  const readDirectory = async (
    item: FileSystemDirectoryEntry,
    folderMap: FolderMap,
    nextFolderId: string
  ) => {
    let entries: any = [];

    const readEntries = (directoryReader) => {
      return new Promise((resolve, reject) => {
        directoryReader.readEntries((results) => {
          if (!results.length) {
            resolve(entries);
          } else {
            entries = entries.concat(Array.from(results));
            readEntries(directoryReader).then(resolve);
          }
        }, reject);
      });
    };

    const directoryReader = item.createReader();
    entries = await readEntries(directoryReader);

    for (const entry of entries) {
      await scanFiles(entry, folderMap, nextFolderId);
    }
  };

  const getFile = async (fileEntry: FileSystemFileEntry): Promise<File> => {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  };

  const uploadFiles = async (folderMap: FolderMap) => {
    await onUpload(Object.values(folderMap));
  };

  return { processBulkUpload };
};
