import { maxFileSize } from '../utils';

export function validateFileName(name: string, existingFileNames?: string[]) {
  if (name.length > 255) {
    throw new Error('Invalid File name. Must be 255 characters or less.');
  }

  if (name === '') {
    throw new Error('Invalid File name. File name cannot be empty.');
  }

  if (existingFileNames && existingFileNames.includes(name)) {
    throw new Error('Invalid File name. File name already exists.');
  }
}

export function validateFileSize(size: number) {
  if (size > maxFileSize) {
    throw new Error('File can not be over 100 MB.');
  }
}
