import React from 'react';
import { useRouter } from 'next/router';
import { FolderAddIcon, SearchCircleIcon } from '@heroicons/react/outline';

export default function EmptyTableRow() {
  const { pathname, query } = useRouter();
  const isDroppable = ['/home'].includes(pathname);

  if (isDroppable) {
    return (
      <div className="table-row" data-testid="empty-folder">
        <div className="table-cell my-10 absolute w-3/4 text-center z-0">
          <FolderAddIcon strokeWidth={1} className="mx-auto h-12 text-03" />
          <h3 className="mt-2 body-01 text-02">{query.folder_id ? 'Folder is empty' : ''}</h3>
          <p className="mt-1 text-sm text-gray-500">
            {query.folder_id
              ? 'Drag and drop a file to upload.'
              : 'Create a folder to get started.'}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="table-row" data-testid="empty-folder">
        <div className="table-cell py-10 absolute w-3/4 text-center z-0">
          <SearchCircleIcon className="mx-auto h-12 text-03" />
          <h3 className="mt-2 body-01 text-02">No results</h3>
        </div>
      </div>
    );
  }
}
