import { useQuery } from '@tanstack/react-query';

import { DatabaseUser } from '@/core/types';
import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';
import { getUserTeams } from '@/core/gateways/private';

interface QueryProps {
  userId: number;
}

async function fetchUserTeams(userId: number) {
  return handleResponse(getUserTeams(userId));
}

export function useFetchUserTeams({ userId }: QueryProps) {
  return useQuery<DatabaseUser[], Error, number[]>(userKeyFactory.currentUserTeams, () =>
    fetchUserTeams(userId)
  );
}
