import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FolderGateway } from '../gateways';
import { FetchedEntities, Folder } from '../types';
import { validateFolderName } from '../validations';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, folderKeyFactory } from '@/core/query-keys';
import { omit } from 'lodash';

interface MutationProps {
  id: string;
  name: string;
  parent_id?: string;
}

async function updateFolder({ ...props }: MutationProps) {
  return handleResponse(FolderGateway.update(omit(props, 'parent_id')));
}

export function useRenameFolder() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string | undefined) => (
    <SuccessNotification
      title="Updated"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Folder <b>{name}</b> was updated successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, any>(updateFolder, {
    onMutate: ({ name, parent_id }) => {
      const queryData = queryClient.getQueryData<FetchedEntities>(
        entityKeyFactory.byEntityId(parent_id)
      );
      const folderNames = queryData?.folders.map((folder: Folder) => folder.name);
      validateFolderName(name, folderNames);
    },
    onSuccess: ({ id, name }) => {
      queryClient.invalidateQueries(folderKeyFactory.byFolderId(id));
      queryClient.invalidateQueries(entityKeyFactory.all);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(name) }
      });
    }
  });
}
