import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import SuccessNotification from '@/components/notifications/success';
import { BulkGateway } from '../gateways';

interface MutationProps {
  folder_ids: string[];
  color: string;
}

async function bulkColor({ folder_ids, color }: MutationProps) {
  return handleResponse(BulkGateway.setColor({ folder_ids, color }));
}

export function useBulkColor() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = () => (
    <SuccessNotification
      title="Color saved"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>The color for the selected folders has been saved.</p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(bulkColor, {
    onSuccess: () => {
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: (data, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: {
            component: successNotification()
          }
        });
      }
    }
  });
}
