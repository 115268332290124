import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AppliedIndexGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import ArchiveNotification from '../components/notifications/archive';
import { useRestoreAppliedIndex } from './';
import { handleResponse } from '@/core/utils/response';
import { appliedIndexKeyFactory, fileKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function archiveAppliedIndex({ id }: MutationProps) {
  return handleResponse(AppliedIndexGateway.archive({ id }));
}

export function useArchiveAppliedIndex() {
  const client = useQueryClient();
  const restoreMutation = useRestoreAppliedIndex();
  const { dispatch } = useNotificationContext();

  const archiveNotification = (id: string, name: string) => {
    return (
      <ArchiveNotification
        title="Archived"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onUndo={() => onUndo(id)}
      >
        <p>Applied index {name} was archived successfully.</p>
      </ArchiveNotification>
    );
  };

  const onUndo = (id: string) => {
    restoreMutation.mutate({ id });
    dispatch({ type: NotificationActionType.ClosePanel });
  };

  return useMutation(archiveAppliedIndex, {
    onSuccess: ({ entity_id, id, index_field }) => {
      client.invalidateQueries(appliedIndexKeyFactory.allByEntity(entity_id));
      client.invalidateQueries(fileKeyFactory.byFileId(entity_id));
      client.invalidateQueries(folderKeyFactory.byFolderId(entity_id));
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: {
          component: archiveNotification(id, index_field.name)
        }
      });
    }
  });
}
