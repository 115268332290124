import { NotificationActionType } from '@/core/reducers/action-types';
import { pluralizer } from '@/core/utils';
import { useNotificationContext } from '@/core/context';
import DeleteNotification from '@/components/notifications/delete';

interface Props {
  folder_ids: string[];
  file_ids: string[];
}

export default function BulkDeleteNotification({ folder_ids, file_ids }: Props) {
  const { dispatch } = useNotificationContext();

  const totalCount = folder_ids.length + file_ids.length;
  return (
    <DeleteNotification
      title="Deleted"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Deleted {totalCount} {pluralizer(totalCount, 'item')}
      </p>
    </DeleteNotification>
  );
}
