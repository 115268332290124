import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BulkGateway } from '../gateways';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import BulkRestoreNotification from '@/components/notifications/bulk-restore-notification';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';

interface MutationProps {
  file_ids?: string[];
  folder_ids?: string[];
}

async function bulkRestore({ file_ids, folder_ids }: MutationProps) {
  return handleResponse(BulkGateway.restore({ file_ids, folder_ids }));
}

export function useBulkRestore() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  return useMutation<any, Error, MutationProps, unknown>(bulkRestore, {
    onSuccess: () => {
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: (data, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: <BulkRestoreNotification {...data} /> }
        });
      }
    }
  });
}
