import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IndexFieldGateway } from '../gateways';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { useNotificationContext } from '../context';
import { handleResponse } from '@/core/utils/response';
import { indexFieldKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function archiveIndexField({ id }: MutationProps) {
  return handleResponse(IndexFieldGateway.archive({ id }));
}

export function useArchiveIndexField() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = ({ name }: { name: string }) => (
    <SuccessNotification
      title="Archived"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Index field {name} has been archived.</p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(archiveIndexField, {
    onSuccess: ({ name }) => {
      queryClient.invalidateQueries(indexFieldKeyFactory.all);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification({ name }) }
      });
    }
  });
}
