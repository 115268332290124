import { MouseEvent } from 'react';
import { Cell, flexRender } from '@tanstack/react-table';

import { EntityRow } from '@/core/types';
import { classNames } from '@/core/utils';

interface Props {
  cell: Cell<EntityRow, unknown>;
}

export default function TableCell({ cell }: Props) {
  const onColumnClick = (e: MouseEvent) => {
    if (cell.column.columnDef.meta?.isEntityPanelDisabled) {
      e.stopPropagation();
    }
  };

  return (
    <div
      className={classNames(
        cell.column.columnDef.meta?.responsiveDisplay || '',
        'table-cell h-10 align-middle px-2'
      )}
      onClick={onColumnClick}
    >
      <div className="py-2.5">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
    </div>
  );
}
