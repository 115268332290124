const userKeys = {
  all: ['allUsers'],
  permissionables: ['permissionables'],
  currentUser: ['currentUser'],
  currentUserTeams: ['currentUser', 'teams'],
  login: ['login'],
  logout: ['logout'],
  integrations: ['integrations'],
  jwt: ['jwt'],
  byPartner: (partner: string) => [...userKeys.integrations, partner]
};

export default userKeys;
