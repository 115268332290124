import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FolderContactGateway } from '../gateways';
import { Contact } from '../types';
import SuccessNotification from '../components/notifications/success';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, folderContactKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  contact: Contact;
  folder_id: string;
}

async function createFolderContact({ ...props }: MutationProps) {
  return handleResponse(FolderContactGateway.create(props));
}

export function useCreateFolderContact() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Linked"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Contact <b>{name}</b> was linked successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(createFolderContact, {
    onSuccess: ({ folder, contact }) => {
      queryClient.invalidateQueries(folderContactKeyFactory.byFolderId(folder.id));
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(folder.parent_id));
      queryClient.invalidateQueries(entityKeyFactory.filtered);
      queryClient.invalidateQueries(folderKeyFactory.byFolderId(folder.id));
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(contact.details.full_name) }
      });
    }
  });
}
