import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrivateGateway } from '../gateways';
import { userKeyFactory } from '@/core/query-keys';

interface HookProps {
  partnerUserId: string;
}

async function deleteUserKey({ partnerUserId }: HookProps) {
  return PrivateGateway.deleteUserKey(partnerUserId);
}

export function useDeleteUserKey() {
  const queryClient = useQueryClient();
  return useMutation(deleteUserKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.byPartner('smartKx'));
    }
  });
}
