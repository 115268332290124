import { useQuery } from '@tanstack/react-query';

import { FetchedEntities, RawFetchedEntities } from '../types';
import { EntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { buildFileEntity, buildFolderEntity } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

type ArchivedEntities = Omit<FetchedEntities, 'breadcrumbs'>;

async function fetchArchivedEntities() {
  return handleResponse(EntityGateway.fetchArchived());
}

export function useFetchArchivedEntities() {
  const { data: user } = useCurrentUser();

  return useQuery<RawFetchedEntities, Error, ArchivedEntities>(
    entityKeyFactory.archived,
    fetchArchivedEntities,
    {
      select: ({ folders, files }) => ({
        folders: folders.map((folder) => buildFolderEntity(folder, user?.userId)),
        files: files.map((file) => buildFileEntity(file, user?.userId))
      })
    }
  );
}
