import { useMutation, useQueryClient } from '@tanstack/react-query';
import { map } from 'lodash';

import { EntityType } from '../types';
import { AppliedIndexGateway } from '../gateways';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { pluralizer } from '../utils';
import { handleResponse } from '@/core/utils/response';
import { appliedIndexKeyFactory, fileKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  values: Record<string, any>;
  entity_id: string;
  entity_type: EntityType;
}

async function applyIndex({ values, entity_id, entity_type }: MutationProps) {
  const indexes = map(values, (value) => ({
    entity_id,
    entity_type,
    index_field_id: value.indexFieldId as string,
    value: value.value
  }));

  return handleResponse(AppliedIndexGateway.create({ indexes }));
}

export function useApplyIndex() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (count: number) => (
    <SuccessNotification
      title="Applied"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        {count} {pluralizer(count, 'index', 'es')} have been applied successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation(applyIndex, {
    onSuccess: (_, { entity_id }) => {
      Promise.all([
        client.invalidateQueries(appliedIndexKeyFactory.allByEntity(entity_id)),
        client.invalidateQueries(fileKeyFactory.byFileId(entity_id)),
        client.invalidateQueries(folderKeyFactory.byFolderId(entity_id)),
        client.invalidateQueries(fileKeyFactory.history(entity_id)),
        client.invalidateQueries(folderKeyFactory.folderHistory(entity_id))
      ]);
    },
    onSettled: (data, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: successNotification(data.count) }
        });
      }
    }
  });
}
