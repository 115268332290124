import { Row } from '@tanstack/react-table';
import { EntityRow, EntityType } from '@/core/types';
import { getType } from '@/core/utils';

const getSortPosition = (
  prevRow: Row<EntityRow>,
  nextRow: Row<EntityRow>,
  prevValue: any,
  nextValue: any
) => {
  if (prevRow.original.type === EntityType.FOLDER && nextRow.original.type === EntityType.FOLDER) {
    return prevValue > nextValue ? 1 : -1;
  }

  if (prevRow.original.type === EntityType.FOLDER) {
    return 1;
  }

  if (nextRow.original.type === EntityType.FOLDER) {
    return -1;
  }

  return prevValue > nextValue ? 1 : -1;
};

export const sortByColumn = (
  prevRow: Row<EntityRow>,
  nextRow: Row<EntityRow>,
  columnId: string
) => {
  const prevValue = prevRow.getValue(columnId);
  const nextValue = nextRow.getValue(columnId);

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};

export const sortByType = (prevRow: Row<EntityRow>, nextRow: Row<EntityRow>) => {
  const prevValue = getType(prevRow.original.extension);
  const nextValue = getType(nextRow.original.extension);

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};

export const sortByDate = (prevRow: Row<EntityRow>, nextRow: Row<EntityRow>, columnId: string) => {
  const prevValue = Date.parse(prevRow.getValue(columnId));
  const nextValue = Date.parse(nextRow.getValue(columnId));

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};

export const sortByUser = (prevRow: Row<EntityRow>, nextRow: Row<EntityRow>) => {
  const prevValue = `${prevRow.original.user?.firstName} ${prevRow.original.user?.lastName}`;
  const nextValue = `${nextRow.original.user?.firstName} ${nextRow.original.user?.lastName}`;

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};
