const FOLDER_NAME_VALIDATION = /^([^\s*/\\><?"|:][^*/\\><?"|:]*)$/;

export function validateFolderName(name: string, existingFolderNames?: string[]) {
  if (name.length > 255) {
    throw new Error('Invalid folder name. Must be 255 characters or less.');
  }

  if (name === '') {
    throw new Error('Invalid Folder name. Folder name cannot be empty.');
  }

  if (!FOLDER_NAME_VALIDATION.test(name)) {
    throw new Error(
      'Invalid folder name. Name cannot start with whitespace or contain the characters *, /, \\, <, >, ?, ", |, or :.'
    );
  }

  if (existingFolderNames && existingFolderNames.includes(name)) {
    throw new Error('Invalid Folder name. Folder name already exists.');
  }
}
