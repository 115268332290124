import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BulkGateway } from '../gateways';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import BulkDeleteNotification from '@/components/notifications/bulk-delete-notification';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, fileKeyFactory } from '@/core/query-keys';

interface MutationProps {
  file_ids?: string[];
  folder_ids?: string[];
}

interface HookProps {
  parent_id?: string;
}

async function bulkDelete({ file_ids, folder_ids }: MutationProps) {
  return handleResponse(BulkGateway.destroy({ file_ids, folder_ids }));
}

export function useBulkDelete({ parent_id }: HookProps) {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  return useMutation<any, Error, MutationProps, unknown>(bulkDelete, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(parent_id));
      queryClient.invalidateQueries(entityKeyFactory.filtered);
      queryClient.invalidateQueries(entityKeyFactory.archived);
      queryClient.invalidateQueries(fileKeyFactory.statsByType());
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: {
          component: <BulkDeleteNotification {...data} parent_id={parent_id} />
        }
      });
    }
  });
}
