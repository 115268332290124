import { useQuery } from '@tanstack/react-query';

import { Permissionable } from '@imaging/types';
import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';
import { getPermissionables } from '@/core/gateways/private';

async function fetchPermissionables() {
  return handleResponse(getPermissionables());
}

export function useFetchPermissionables() {
  return useQuery<Permissionable[], Error, Permissionable[]>(userKeyFactory.permissionables, () =>
    fetchPermissionables()
  );
}
