import { FeatureFlag } from '@/core/utils';
import { useEffect, useState } from 'react';
import { useSplitClient } from '@splitsoftware/splitio-react';

export function useFeatureFlag(featureFlag: FeatureFlag) {
  const [isFeatureEnabled, setFeatureEnabled] = useState(false);
  const { client: splitClient, isReady, ...args } = useSplitClient();

  useEffect(() => {
    if (splitClient && isReady) {
      const treatment = splitClient.getTreatment(featureFlag);
      setFeatureEnabled(treatment === 'on');
    }
  }, [splitClient, isReady]);

  return { isFeatureEnabled, isReady, ...args };
}
