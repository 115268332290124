import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { HistoryItem } from '../types';
import { HistoryGateway } from '../gateways';
import { buildHistoryFilters, handleResponse } from '@/core/utils';
import { historyKeyFactory } from '@/core/query-keys';

interface Filters {
  actions: string[];
  users: number[];
  date?: { start: string; end: string };
}

interface HookProps extends Omit<UseMutationOptions<HistoryItem[]>, 'attributes'> {
  filters: Filters;
}

async function exportHistory({ filters }: HookProps) {
  const payload = buildHistoryFilters(filters);
  return handleResponse(HistoryGateway.fetchCSV(payload));
}

export function useExportHistoryLog({ filters, ...options }: HookProps) {
  return useMutation<HistoryItem[]>(
    historyKeyFactory.export(filters),
    () => exportHistory({ filters }),
    {
      ...options
    }
  );
}
