import { useQuery } from '@tanstack/react-query';

import { FileGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { fileKeyFactory } from '@/core/query-keys';

interface FileStatsByType {
  extension: string;
  count: number;
}

async function fileStatsByType() {
  return handleResponse(FileGateway.fetchStatsByType());
}

export function useFileStatsByType() {
  return useQuery<FileStatsByType[]>(fileKeyFactory.statsByType(), fileStatsByType, {
    initialData: []
  });
}
