import { useMutation } from '@tanstack/react-query';

import { handleResponse } from '@/core/utils';
import { PrivateGateway } from '@/core/gateways';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import SuccessNotification from '@/components/notifications/success';

interface MutationProps {
  id: string;
  password: string;
  adminUser: string;
  adminPass: string;
}

async function changePassword({ id, password }: MutationProps) {
  return handleResponse(PrivateGateway.changePassword(id, password));
}

async function validateAdmin({ adminUser, adminPass, id, password }: MutationProps) {
  const response = await PrivateGateway.authenticateUser(adminUser, adminPass);
  if (!response.ok) {
    throw new Error('Invalid Admin Password');
  }
  return changePassword({ id, password } as MutationProps);
}

export function useChangePassword() {
  const { dispatch } = useNotificationContext();

  const successNotification = () => {
    return (
      <SuccessNotification
        title="Updated"
        onClose={() => {
          dispatch({ type: NotificationActionType.ClosePanel });
        }}
      >
        <p>Password updated successfully.</p>
      </SuccessNotification>
    );
  };

  return useMutation(validateAdmin, {
    onSuccess: () => {
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification() }
      });
    }
  });
}
