import { useState } from 'react';

export function useTogglePanelSection() {
  const [isDetailsOpen, setDetailsOpen] = useState(true);
  const [isLinkedContactOpen, setLinkedContactOpen] = useState(false);
  const [isIndexOpen, setIndexOpen] = useState(false);
  const [isHistoryOpen, setHistoryOpen] = useState(false);

  const details = () => {
    if (!isDetailsOpen) {
      setDetailsOpen(true);
      setLinkedContactOpen(false);
      setIndexOpen(false);
      setHistoryOpen(false);
    }
  };

  const linkedContacts = () => {
    if (!isLinkedContactOpen) {
      setDetailsOpen(false);
      setLinkedContactOpen(true);
      setIndexOpen(false);
      setHistoryOpen(false);
    }
  };

  const index = () => {
    if (!isIndexOpen) {
      setDetailsOpen(false);
      setLinkedContactOpen(false);
      setIndexOpen(true);
      setHistoryOpen(false);
    }
  };

  const history = () => {
    if (!isHistoryOpen) {
      setDetailsOpen(false);
      setLinkedContactOpen(false);
      setIndexOpen(false);
      setHistoryOpen(true);
    }
  };

  return {
    index,
    history,
    details,
    linkedContacts,
    isIndexOpen,
    isDetailsOpen,
    isHistoryOpen,
    isLinkedContactOpen
  };
}
