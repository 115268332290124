import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { File, RawFile } from '../types';
import { FileGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { fileKeyFactory } from '@/core/query-keys';
import { useCurrentUser } from '@/core/hooks/use-current-user';
import { buildFileEntity } from '@/core/utils';

interface QueryProps {
  id: string;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawFile, Error, File>;
}

async function fetchFile({ id }: QueryProps) {
  return handleResponse(FileGateway.fetch({ id }));
}

export function useFetchFile({ id, options = {} }: HookProps) {
  const { data: user } = useCurrentUser();

  return useQuery<RawFile, Error, File>(fileKeyFactory.byFileId(id), () => fetchFile({ id }), {
    select: (data) => buildFileEntity(data, user?.userId),
    enabled: !!user,
    ...options
  });
}
