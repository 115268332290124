import { KeyboardEvent } from 'react';

interface KeyPressEvent {
  key: string;
  action(): void;
}

export function useKeyPress(
  events: KeyPressEvent[],
  preventDefault?: boolean,
  stopPropagation?: boolean
) {
  const handleKeyUp = (e: KeyboardEvent<any>) => {
    for (const event of events) {
      if (e.key === event.key) {
        if (stopPropagation) {
          e.stopPropagation();
        }
        if (preventDefault) {
          e.preventDefault();
        }
        event.action();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<any>) => {
    handleKeyUp(e);
  };

  return { handleKeyUp, handleKeyDown };
}
