import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import type { Breadcrumb } from '../types';
import { FolderGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { breadcrumbKeyFactory } from '@/core/query-keys';

interface QueryProps {
  parentId?: string;
}

interface HookProps {
  parentId?: string;
  options?: UseQueryOptions<Breadcrumb[]>;
}

async function fetchBreadcrumbs({ parentId }: QueryProps) {
  if (parentId) {
    return handleResponse(FolderGateway.fetchBreadcrumbs({ parentId }));
  } else {
    return [];
  }
}

export function useBreadcrumbs({ parentId, options = {} }: HookProps) {
  return useQuery<Breadcrumb[]>(
    breadcrumbKeyFactory.byParentId(parentId),
    () => fetchBreadcrumbs({ parentId }),
    {
      enabled: !!parentId,
      initialData: () => [],
      ...options
    }
  );
}
