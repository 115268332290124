import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FolderContactGateway } from '../gateways';
import { useNotificationContext } from '../context';
import DeleteNotification from '../components/notifications/delete';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, folderContactKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
  contact_id: string;
}

async function deleteFolderContact({ ...props }: MutationProps) {
  return handleResponse(FolderContactGateway.destroy(props));
}

export function useDeleteFolderContact() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const deleteNotification = (name: string) => {
    return (
      <DeleteNotification
        title="Deleted"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
      >
        <p>Contact {name} was unlinked successfully.</p>
      </DeleteNotification>
    );
  };

  return useMutation(deleteFolderContact, {
    onSuccess: ({ folder, contact }) => {
      queryClient.invalidateQueries(folderContactKeyFactory.byFolderId(folder.id));
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(folder.parent_id));
      queryClient.invalidateQueries(entityKeyFactory.filtered);
      queryClient.invalidateQueries(folderKeyFactory.byFolderId(folder.id));
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: deleteNotification(contact.details.full_name) }
      });
    }
  });
}
