import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrivateGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';

interface HookProps {
  partnerAppId: string;
}

async function createUserKey({ partnerAppId }: HookProps) {
  return handleResponse(PrivateGateway.createUserKey(partnerAppId));
}

export function useCreateUserKey() {
  const queryClient = useQueryClient();
  return useMutation(createUserKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.byPartner('smartKx'));
    }
  });
}
