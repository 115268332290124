import { useInfiniteQuery } from '@tanstack/react-query';

import { EntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { EntityType, PagedSearchedEntities, SearchFilters } from '@/core/types';

interface QueryProps {
  filters: SearchFilters;
  pageParam?: number;
}

async function searchEntities({ filters, pageParam }: QueryProps) {
  let response = await handleResponse(EntityGateway.missingIndexes({ filters, pageParam }));
  response = {
    ...response,
    files: response.files.map((file) => {
      return {
        ...file,
        type: EntityType.FILE
      };
    })
  };
  return response;
}

export function useMissingIndexSearch({ filters, options = {} }) {
  return useInfiniteQuery<PagedSearchedEntities, Error>(
    entityKeyFactory.withMissingIndexes(filters),
    ({ pageParam = 1 }) => searchEntities({ pageParam, filters }),
    {
      getNextPageParam: ({ nextPage }) => nextPage,
      ...options
    }
  );
}
