import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { FolderGateway } from '../gateways';
import { Folder, RawFolder } from '../types';
import { handleResponse } from '@/core/utils/response';
import { folderKeyFactory } from '@/core/query-keys';
import { useCurrentUser } from '@/core/hooks/use-current-user';
import { buildFolderEntity } from '@/core/utils';

interface QueryProps {
  id: string;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawFolder, Error, Folder>;
}

async function fetchFolder({ id }: QueryProps) {
  return handleResponse(FolderGateway.fetch({ id }));
}

export function useFetchFolder({ id, options }: HookProps) {
  const { data: user } = useCurrentUser();

  return useQuery<RawFolder, Error, Folder>(
    folderKeyFactory.byFolderId(id),
    () => fetchFolder({ id }),
    {
      select: (data) => buildFolderEntity(data, user?.userId),
      enabled: !!user,
      ...options
    }
  );
}
