import { useQuery } from '@tanstack/react-query';

import { IndexFieldGateway } from '../gateways';
import { AppliedIndex } from '../types';
import { handleResponse } from '@/core/utils/response';
import { indexFieldKeyFactory } from '@/core/query-keys';

interface QueryProps {
  id: string;
}

type HookProps = QueryProps;

async function fetchAppliedIndexValues({ id }: QueryProps) {
  return handleResponse(IndexFieldGateway.appliedValues({ id }));
}

export function useFetchAppliedIndexValues({ id }: HookProps) {
  return useQuery<AppliedIndex[], Error, AppliedIndex[], any>(
    indexFieldKeyFactory.distinctValues(id),
    () => fetchAppliedIndexValues({ id })
  );
}
