import { useQuery } from '@tanstack/react-query';

import { PrivateGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';

interface QueryProps {
  partnerAppId: string;
}

async function fetchUserKey({ partnerAppId }: QueryProps) {
  return handleResponse(PrivateGateway.getUserKey(partnerAppId));
}

export function useFetchUserKey({ partnerAppId }: QueryProps) {
  return useQuery(userKeyFactory.byPartner('smartKx'), () => fetchUserKey({ partnerAppId }));
}
