import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import { IndexFieldGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { historyKeyFactory, indexFieldKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
  name?: string;
  description?: string;
  values?: Record<string, any>;
  oldValues?: Record<string, any>;
}

async function updateIndexField({ ...props }: MutationProps) {
  return handleResponse(IndexFieldGateway.update(omit(props, 'type', 'archived')));
}

export function useUpdateIndexField() {
  const client = useQueryClient();

  return useMutation<any, Error, MutationProps, any>(updateIndexField, {
    onSuccess: () => {
      client.invalidateQueries(indexFieldKeyFactory.all);
      client.invalidateQueries(historyKeyFactory.all);
    }
  });
}
