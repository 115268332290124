import { useCallback, useMemo, useRef, useState } from 'react';
import type { DragEvent } from 'react';

type DivDragEvent = DragEvent<HTMLDivElement>;

interface Props {
  onDragEnter?(e: DivDragEvent): void;
  onDragOver?(e: DivDragEvent): void;
  onDragLeave?(e: DivDragEvent): void;
  onDrop?(e: DragEvent): void;
  onDragStart?(e: DivDragEvent): void;
}

export function useDropzone({ ...props }: Props = {}) {
  const { onDragEnter, onDragOver, onDragLeave, onDrop, onDragStart } = props;
  const dragTargetsRef = useRef<EventTarget[]>([]);
  const rootRef = useRef<HTMLDivElement>(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const onDragEnterCb = useCallback(
    (e: DivDragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragTargetsRef.current = [...dragTargetsRef.current, e.target];

      setIsDragActive(true);

      if (onDragEnter) {
        onDragEnter(e);
      }
    },
    [onDragEnter]
  );

  const onDragOverCb = useCallback(
    (e: DivDragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onDragOver) {
        onDragOver(e);
      }
    },
    [onDragOver]
  );

  const onDragStartCb = useCallback(
    (e: DivDragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onDragStart) {
        onDragStart(e);
      }
    },
    [onDragStart]
  );

  const onDragLeaveCb = useCallback(
    (e: DivDragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const targets = dragTargetsRef.current.filter(
        (target) => rootRef.current && rootRef.current.contains(target as Node)
      );

      const targetIdx = targets.indexOf(e.target);
      if (targetIdx !== -1) {
        targets.splice(targetIdx, 1);
      }
      dragTargetsRef.current = targets;
      if (targets.length > 0) {
        return;
      } else {
        setIsDragActive(false);
      }

      if (onDragLeave) {
        onDragLeave(e);
      }
    },
    [onDragLeave]
  );

  const onDropCb = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      setIsDragActive(false);

      if (onDrop) {
        onDrop(e);
      }
    },
    [onDrop]
  );

  const getRootProps = useMemo(
    () => ({
      ref: rootRef,
      onDragEnter: onDragEnterCb,
      onDragOver: onDragOverCb,
      onDragLeave: onDragLeaveCb,
      onDrop: onDropCb,
      onDragStart: onDragStartCb
    }),
    [rootRef, onDragEnterCb, onDragOverCb, onDragLeaveCb, onDropCb, onDragStartCb]
  );

  return { getRootProps, isDragActive };
}
