import { useQuery } from '@tanstack/react-query';

import { EntityType, HistoryItem } from '@/core/types';
import { fileKeyFactory, folderKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';
import { FileGateway, FolderGateway } from '@/core/gateways';

interface QueryProps {
  entityId: string;
  entityType: EntityType;
}

async function fetchHistory({ entityId, entityType }: QueryProps) {
  if (entityType === EntityType.FILE) {
    return handleResponse(FileGateway.fetchHistory({ id: entityId }));
  }

  return handleResponse(FolderGateway.fetchHistory({ id: entityId }));
}

function getKeyFactory({ entityId, entityType }: QueryProps) {
  if (entityType === EntityType.FILE) {
    return fileKeyFactory.history(entityId);
  }

  return folderKeyFactory.folderHistory(entityId);
}

export function useFetchHistory(props: QueryProps) {
  return useQuery<HistoryItem[]>(getKeyFactory(props), () => fetchHistory(props));
}
