import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FolderGateway } from '../gateways';
import { useNotificationContext } from '../context';
import ArchiveNotification from '../components/notifications/delete';
import { NotificationActionType } from '../reducers/action-types';
import { useRestoreFolder } from './';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function archiveFolder({ id }: MutationProps) {
  return handleResponse(FolderGateway.archive({ id }));
}

export function useArchiveFolder() {
  const queryClient = useQueryClient();
  const restoreMutation = useRestoreFolder();
  const { dispatch } = useNotificationContext();

  const archiveNotification = (id: string, updated_at: string, name: string) => {
    return (
      <ArchiveNotification
        title="Archived"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onUndo={() => onUndo(id, updated_at)}
      >
        <p>Folder {name} was archived successfully.</p>
      </ArchiveNotification>
    );
  };

  const onUndo = (id: string, updated_at: string) => {
    restoreMutation.mutate({ id, updated_at });
  };

  return useMutation<any, Error, MutationProps, unknown>(archiveFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: ({ id, updated_at, name }, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: archiveNotification(id, updated_at, name) }
        });
      }
    }
  });
}
