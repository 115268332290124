import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrivateGateway } from '@/core/gateways';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import { userKeyFactory } from '@/core/query-keys';
import { CreateUserPayload } from '@/core/types';
import SuccessNotification from '@/components/notifications/success';

async function createUser(payload: CreateUserPayload) {
  const response = await PrivateGateway.createUser(payload);
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
}

export function useCreateUser() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (user: any) => {
    return (
      <SuccessNotification
        title="Created"
        onClose={() => {
          dispatch({ type: NotificationActionType.ClosePanel });
        }}
      >
        <p>
          Member{' '}
          <b>
            {user.firstName} {user.lastName}
          </b>{' '}
          was added successfully.
        </p>
      </SuccessNotification>
    );
  };

  return useMutation(createUser, {
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries(userKeyFactory.all),
        queryClient.invalidateQueries(userKeyFactory.currentUser)
      ]);
    },
    onSettled: (data, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: successNotification(data) }
        });
      }
    }
  });
}
