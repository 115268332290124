import { useQuery } from '@tanstack/react-query';

import { AppliedIndexGateway } from '../gateways';
import { AppliedIndex } from '../types';
import { handleResponse } from '@/core/utils/response';
import { appliedIndexKeyFactory } from '@/core/query-keys';

interface QueryProps {
  entityId: string;
}

type HookProps = QueryProps;

async function fetchAppliedIndexes({ entityId }: QueryProps) {
  return handleResponse(AppliedIndexGateway.fetch({ entityId }));
}

export function useFetchAppliedIndexes({ entityId }: HookProps) {
  return useQuery<AppliedIndex[], Error, AppliedIndex[], any>(
    appliedIndexKeyFactory.allByEntity(entityId),
    () => fetchAppliedIndexes({ entityId })
  );
}
