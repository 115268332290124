import { useMutation, useQueryClient } from '@tanstack/react-query';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, folderKeyFactory } from '@/core/query-keys';
import { PermissionProps } from '@imaging/types';
import { PermissionGateway } from '@/core/gateways';

interface MutationProps {
  folder_id: string;
  permissions: PermissionProps[];
}

async function createPermissions({ ...props }: MutationProps) {
  return handleResponse(PermissionGateway.create(props));
}

export function useCreatePermissions() {
  const queryClient = useQueryClient();

  return useMutation(createPermissions, {
    onSuccess: ({ folder_id }) => {
      queryClient.invalidateQueries(folderKeyFactory.byFolderId(folder_id));
      queryClient.invalidateQueries(entityKeyFactory.all);
    }
  });
}
