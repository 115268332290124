import { useQuery } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import localforage from 'localforage';

import { HistoryGateway } from '../gateways';
import { userKeyFactory } from '@/core/query-keys';

async function logout() {
  await HistoryGateway.logout();
  const response = await fetch('/api/auth/logout');

  if (response.ok) {
    return response.text();
  } else {
    throw new Error('Could not logout.');
  }
}

export function useLogout() {
  return useQuery(userKeyFactory.logout, logout, {
    onSettled: async () => {
      await localforage.clear();
      datadogRum.clearUser();
    }
  });
}
