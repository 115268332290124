import { useQuery } from '@tanstack/react-query';

import { contactKeyFactory } from '@/core/query-keys';
import { useFetchIntegrations } from '@/core/hooks';
import { Contact } from '../types';
import { ContactGateway } from '../gateways';

interface HookProps {
  query: string;
}

interface FetchedContacts {
  contacts: [
    {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
      dob: string;
      spouse_name: string;
      addresses: Record<string, any>[];
      phones: Record<string, any>[];
      emails: Record<string, any>[];
      photos: Record<string, any>[];
    }
  ];
  linkedContacts: number[];
}

async function searchContacts({ query }: HookProps) {
  const response = await ContactGateway.search(query);
  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not fetch all contacts.');
  }
}

export function useSearchContacts({ query }: HookProps) {
  const { data: integration } = useFetchIntegrations();

  return useQuery<FetchedContacts, Error, Contact[], any>(
    contactKeyFactory.search(query),
    () => searchContacts({ query }),
    {
      enabled: integration?.canLinkContacts && query.length >= 2,
      select: ({ contacts, linkedContacts }) => {
        return contacts.map((contact) => ({
          external_id: contact.id,
          details: contact,
          isLinked: linkedContacts.includes(contact.id)
        }));
      }
    }
  );
}
