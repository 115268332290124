import { Row } from '@tanstack/react-table';

import { EntityRow } from '@/core/types';
import { classNames } from '@/core/utils';
import { useUiContext } from '@/core/context';
import TableCell from '@/components/table/table-cell';

interface Props {
  row: Row<EntityRow>;
  rowSelectEnabled?: boolean;
  toggleAllRowsSelected(value: boolean | undefined): void;
}

export default function TableRow({ row, rowSelectEnabled, toggleAllRowsSelected }: Props) {
  const {
    selectedEntity,
    closeEntityPanel,
    setSelectedEntity,
    setIsEntityPanelOpen,
    isEntityPanelOpen
  } = useUiContext();

  const onRowClick = () => {
    if (!rowSelectEnabled) return;

    if (row.original.archived) {
      row.toggleSelected();
      return;
    }

    toggleAllRowsSelected(false);

    if (selectedEntity?.id === row.original.id && isEntityPanelOpen) {
      closeEntityPanel();
    } else {
      setIsEntityPanelOpen(true);
      setSelectedEntity({
        id: row.original.id,
        type: row.original.type
      });
    }
  };

  return (
    <div
      onClick={onRowClick}
      className={classNames(
        row.getIsSelected() || selectedEntity?.id === row.original.id
          ? 'bg-selected-01'
          : 'hover:bg-ui-01',
        rowSelectEnabled ? 'cursor-pointer' : 'cursor-auto',
        'text-left focus-visible:ring-focus-ring focus-visible:ring-4',
        'focus:outline-none border-b border-ui-02 table-row'
      )}
      data-testid="data-row"
    >
      {row.getAllCells().map((cell) => {
        return <TableCell key={`table-cell-${cell.id}`} cell={cell} />;
      })}
    </div>
  );
}
