import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import { identify } from '@fullstory/browser';

import { CurrentUser } from '@imaging/types';
import { Environment, handleResponse } from '@/core/utils';
import { userKeyFactory } from '@/core/query-keys';
import { AuthGateway } from '../gateways';

interface FullStoryUser {
  id: string;
  userId: number;
  databaseId: number;
  userName: string;
}

async function currentUserQueryFunction() {
  return handleResponse(AuthGateway.getCurrentUser());
}

function setFullStoryUser({ id, userId, databaseId, userName }: FullStoryUser) {
  if (Environment.isDeployed) {
    identify(id, {
      userId,
      databaseId,
      userName
    });
  }
}

export function useCurrentUser(options: UseQueryOptions<CurrentUser> = {}) {
  return useQuery<CurrentUser>(userKeyFactory.currentUser, currentUserQueryFunction, {
    onError: () => {
      window.location.href = process.env.NEXT_PUBLIC_ACCOUNTS_URL as string;
    },
    onSuccess: ({ id, userId, databaseId, userName }) => {
      datadogRum.setUser({
        userId: userId.toString(),
        databaseId,
        username: userName
      });

      setFullStoryUser({ id, userId, databaseId, userName });
    },
    ...options
  });
}
