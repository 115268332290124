import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IntegrationGateway } from '@/core/gateways';
import { handleResponse } from '@/core/utils/response';
import { userKeyFactory } from '@/core/query-keys';

interface QueryProps {
  action: 'enable' | 'disable';
  databaseId: number;
}

async function enableIntegration(databaseId: number) {
  return handleResponse(IntegrationGateway.enable(databaseId));
}

async function disableIntegration(databaseId: number) {
  return handleResponse(IntegrationGateway.disable(databaseId));
}

async function toggleIntegration({ action, databaseId }: QueryProps) {
  if (action === 'enable') {
    return enableIntegration(databaseId);
  } else {
    return disableIntegration(databaseId);
  }
}

export function useRedtailCrmIntegration() {
  const queryClient = useQueryClient();
  return useMutation(userKeyFactory.byPartner('Redtail CRM'), toggleIntegration, {
    onSuccess: () => queryClient.invalidateQueries(userKeyFactory.byPartner('Redtail CRM'))
  });
}
