import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FolderGateway } from '../gateways';
import { FetchedEntities, Folder } from '../types';
import { validateFolderName } from '../validations';
import { useNotificationContext } from '../context';
import SuccessNotification from '../components/notifications/success';
import { NotificationActionType } from '../reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';

export interface MutationProps {
  id: string;
  name: string;
  parent_id?: string;
}

async function copyFolder({ ...props }: MutationProps) {
  return handleResponse(FolderGateway.copy(props));
}

export function useCopyFolder() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Copied"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Folder <b>{name}</b> was copied successfully.
      </p>
    </SuccessNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(copyFolder, {
    onMutate: ({ name, parent_id }) => {
      const queryData = queryClient.getQueryData<FetchedEntities>(
        entityKeyFactory.byEntityId(parent_id)
      );
      const folderNames = queryData?.folders.map((folder: Folder) => folder.name);
      validateFolderName(name, folderNames);
    },
    onSuccess: ({ parent_id, name }) => {
      queryClient.invalidateQueries(entityKeyFactory.byEntityId(parent_id));
      queryClient.invalidateQueries(entityKeyFactory.filtered);
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(name) }
      });
    }
  });
}
