import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrivateGateway } from '../gateways';
import { userKeyFactory } from '@/core/query-keys';
import { handleResponse } from '@/core/utils';

interface HookProps {
  partnerUserId: string;
  partnerAppId: string;
}

async function rotateUserKey({ partnerUserId, partnerAppId }: HookProps) {
  return handleResponse(PrivateGateway.rotateUserKey(partnerUserId, partnerAppId));
}

export function useRotateUserKey() {
  const queryClient = useQueryClient();
  return useMutation(rotateUserKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeyFactory.byPartner('smartKx'));
    }
  });
}
