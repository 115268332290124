import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { folderKeyFactory } from '@/core/query-keys';
import { FolderGateway } from '@/core/gateways';
import { Folder, RawFolder } from '@/core/types';
import { buildFolderEntity, handleResponse } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

interface QueryProps {
  parentId?: string;
  next: number;
}

interface HookProps {
  parentId?: string;
  options?: UseInfiniteQueryOptions<RawPagedFolders, Error, Folder[]>;
}

interface PagingMeta {
  next: number | null;
}

interface RawPagedFolders {
  data: RawFolder[];
  meta: PagingMeta;
}

const fetchPagedFolders = async ({ parentId, next }: QueryProps) => {
  return handleResponse(FolderGateway.fetchManyPaged({ parentId, page: next }));
};

export function useFetchPagedFolders({ parentId, options = {} }: HookProps) {
  const { data: user } = useCurrentUser();

  return useInfiniteQuery<RawPagedFolders, Error, Folder[]>(
    folderKeyFactory.byParentId(parentId),
    ({ pageParam = 1 }) => fetchPagedFolders({ parentId, next: pageParam }),
    {
      getNextPageParam: ({ meta }) => meta.next || undefined,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((value) =>
            value.data.map((folder) => buildFolderEntity(folder, user?.userId))
          )
        };
      },
      enabled: !!user,
      ...options
    }
  );
}
