import { useQuery } from '@tanstack/react-query';

import { PrivateGateway } from '@/core/gateways';
import { userKeyFactory } from '@/core/query-keys';

async function fetchJwt() {
  const response = await PrivateGateway.generateToken();

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Could not fetch token.');
  }
}

export function useFetchJwt() {
  return useQuery(userKeyFactory.jwt, fetchJwt);
}
