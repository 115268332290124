import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AppliedIndexGateway } from '../gateways';
import { useNotificationContext } from '../context';
import { NotificationActionType } from '../reducers/action-types';
import SuccessNotification from '../components/notifications/success';
import { handleResponse } from '@/core/utils/response';
import { appliedIndexKeyFactory, fileKeyFactory, folderKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function restoreAppliedIndex({ id }: MutationProps) {
  return handleResponse(AppliedIndexGateway.restore({ id }));
}

export function useRestoreAppliedIndex() {
  const client = useQueryClient();
  const { dispatch } = useNotificationContext();

  const successNotification = (name: string) => (
    <SuccessNotification
      title="Restored"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Applied index {name} was restored successfully.</p>
    </SuccessNotification>
  );

  return useMutation(restoreAppliedIndex, {
    onSuccess: ({ entity_id, index_field }) => {
      client.invalidateQueries(appliedIndexKeyFactory.allByEntity(entity_id));
      client.invalidateQueries(fileKeyFactory.byFileId(entity_id));
      client.invalidateQueries(folderKeyFactory.byFolderId(entity_id));
      dispatch({
        type: NotificationActionType.OpenPanel,
        payload: { component: successNotification(index_field.name) }
      });
    }
  });
}
