import { useQuery } from '@tanstack/react-query';

import { RawFetchedEntities, FetchedEntities } from '@/core/types';
import { entityKeyFactory } from '@/core/query-keys';
import { buildFolderEntity, handleResponse } from '@/core/utils';
import { EntityGateway } from '@/core/gateways';
import { useCurrentUser } from '@/core/hooks/use-current-user';

async function fetchShared() {
  return handleResponse(EntityGateway.shared());
}

export function useFetchShared() {
  const { data: user } = useCurrentUser();

  return useQuery<RawFetchedEntities, Error, FetchedEntities>(
    entityKeyFactory.shared,
    fetchShared,
    {
      select: ({ folders }) => {
        return {
          folders: folders.map((folder) => buildFolderEntity(folder, user?.userId)),
          files: [],
          breadcrumbs: []
        };
      }
    }
  );
}
