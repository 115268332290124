import { Column } from '@tanstack/react-table';
import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from '@heroicons/react/solid';

import { sortByColumn, sortByType, sortByDate, sortByUser } from './entity';
import { sortUserByName, sortUserByColumn, sortUserByDate } from './user';

const renderSortIcon = (column: Column<any>) => {
  const sortDir = column.getIsSorted();

  if (sortDir === 'desc') {
    return <ArrowNarrowDownIcon className="h-4 text-icon-01" />;
  } else if (sortDir === 'asc') {
    return <ArrowNarrowUpIcon className="h-4 text-icon-01" />;
  }
};

export {
  sortByColumn,
  sortByType,
  sortByDate,
  sortByUser,
  sortUserByName,
  sortUserByDate,
  sortUserByColumn,
  renderSortIcon
};
