import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PermissionGateway } from '../gateways';
import { entityKeyFactory } from '@/core/query-keys';

interface MutationProps {
  user_id: number;
}

async function deletePermissions({ user_id }: MutationProps) {
  return PermissionGateway.deleteByUser({ user_id });
}

export function useDeleteUserPermissions() {
  const client = useQueryClient();

  return useMutation(deletePermissions, {
    onSuccess: () => {
      client.invalidateQueries(entityKeyFactory.all);
    }
  });
}
