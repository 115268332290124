import { Row } from '@tanstack/react-table';

import { DatabaseUser } from '@/core/types';

const getSortPosition = (
  prevRow: Row<DatabaseUser>,
  nextRow: Row<DatabaseUser>,
  prevValue: any,
  nextValue: any
) => {
  return prevValue > nextValue ? 1 : -1;
};

export const sortUserByColumn = (
  prevRow: Row<DatabaseUser>,
  nextRow: Row<DatabaseUser>,
  columnId: string
) => {
  const prevValue = prevRow.getValue(columnId);
  const nextValue = nextRow.getValue(columnId);

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};

export const sortUserByDate = (
  prevRow: Row<DatabaseUser>,
  nextRow: Row<DatabaseUser>,
  columnId: string
) => {
  const prevValue = Date.parse(prevRow.getValue(columnId));
  const nextValue = Date.parse(nextRow.getValue(columnId));

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};

export const sortUserByName = (prevRow: Row<DatabaseUser>, nextRow: Row<DatabaseUser>) => {
  const prevValue = `${prevRow.original.firstName} ${prevRow.original.lastName}`;
  const nextValue = `${nextRow.original.firstName} ${nextRow.original.lastName}`;

  return getSortPosition(prevRow, nextRow, prevValue, nextValue);
};
