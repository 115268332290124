import { useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EntityType, FetchedEntities, MoveEntities, MoveEntity } from '@/core/types';
import { useNotificationContext } from '@/core/context';
import { NotificationActionType } from '@/core/reducers/action-types';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { checkDestinationDuplication } from '@/core/validations';
import SuccessNotification from '@/components/notifications/success';
import ErrorNotification from '@/components/notifications/error';
import { BulkGateway } from '../gateways';

interface MutationProps {
  entities?: MoveEntity[];
  parent_id?: string;
}

export function useBulkMove() {
  const queryClient = useQueryClient();
  const { dispatch } = useNotificationContext();

  const duplicates = useRef<MoveEntities>({
    entities: []
  });

  async function bulkMove({ entities, parent_id }: MutationProps) {
    const filtered = filterDuplicates(entities);
    const file_ids = filtered
      .filter((entity) => entity.kind === EntityType.FILE)
      .map((file) => file.id);
    const folder_ids = filtered
      .filter((entity) => entity.kind === EntityType.FOLDER)
      .map((folder) => folder.id);
    return handleResponse(BulkGateway.move({ file_ids, folder_ids, parent_id: parent_id || null }));
  }

  function filterDuplicates(entities): MoveEntity[] {
    const dupeIds = duplicates.current.entities.map((dupe) => dupe.id);
    return entities.filter((entity) => !dupeIds.includes(entity.id));
  }

  const successNotification = () => (
    <SuccessNotification
      title="Moved"
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>Selected folders/files moved.</p>
    </SuccessNotification>
  );

  const duplicateNotification = () => (
    <ErrorNotification
      title={`Unable to move ${duplicates.current.entities.length} items`}
      onClose={() => {
        dispatch({ type: NotificationActionType.ClosePanel });
      }}
    >
      <p>
        Some items could not be moved, the names already exist in the destination. Rename and try
        again.
      </p>
    </ErrorNotification>
  );

  return useMutation<any, Error, MutationProps, unknown>(bulkMove, {
    onMutate: ({ entities, parent_id }) => {
      const queryData = queryClient.getQueryData<FetchedEntities>(
        entityKeyFactory.byEntityId(parent_id)
      );
      duplicates.current = checkDestinationDuplication(entities, queryData, parent_id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: (_, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: {
            component: duplicates.current.entities.length
              ? duplicateNotification()
              : successNotification()
          }
        });
      }
    }
  });
}
