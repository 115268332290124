import { RefCallback, useRef, useCallback, useMemo } from 'react';
import { createPopper, Options } from '@popperjs/core';

export function usePopper(): [
  RefCallback<HTMLElement | null>,
  RefCallback<HTMLElement | null>,
  RefCallback<void>
] {
  const reference = useRef<HTMLElement | null>(null);
  const trigger = useRef<HTMLElement | null>(null);

  const options: Partial<Options> = {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 2] }
      },
      {
        name: 'hide'
      },
      {
        name: 'flip',
        options: {
          flipVariations: true
        }
      },
      {
        name: 'sameWidthAsContainer',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles']
      }
    ]
  };

  const cleanupCallback = useRef<() => void>();

  const instantiatePopper = useCallback(() => {
    if (!reference.current || !trigger.current) return;

    if (cleanupCallback.current) {
      cleanupCallback.current();
    }

    cleanupCallback.current = createPopper(reference.current, trigger.current, options).destroy;
  }, [reference, trigger, cleanupCallback, options]);

  const hide = () => {
    reference.current?.click();
  };

  return useMemo(
    () => [
      (referenceDomNode) => {
        reference.current = referenceDomNode;
        instantiatePopper();
      },
      (popperDomNode) => {
        trigger.current = popperDomNode;
        instantiatePopper();
      },
      () => {
        hide();
      }
    ],
    [reference, trigger, instantiatePopper]
  );
}
