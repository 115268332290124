import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { EntityGateway } from '../gateways';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory } from '@/core/query-keys';
import { RawSearchedEntities, SearchedEntities } from '@/core/types/entity';
import { buildFileEntity, buildFolderEntity } from '@/core/utils';
import { useCurrentUser } from '@/core/hooks/use-current-user';

interface QueryProps {
  query: string;
}

interface HookProps extends QueryProps {
  options?: UseQueryOptions<RawSearchedEntities, unknown, SearchedEntities>;
}

async function searchEntities(query: string) {
  return handleResponse(EntityGateway.autocomplete(query));
}

export function useAutocompleteEntities({ query, options = {} }: HookProps) {
  const { data: user } = useCurrentUser();

  return useQuery<RawSearchedEntities, unknown, SearchedEntities>(
    entityKeyFactory.withAutocomplete(query.trimEnd()),
    () => searchEntities(query.trimEnd()),
    {
      select: ({ folders, files, folder_contacts }): SearchedEntities => ({
        files: files.map((file) => buildFileEntity(file, user?.userId)),
        folders: folders.map((folder) => buildFolderEntity(folder, user?.userId)),
        folder_contacts
      }),
      ...options
    }
  );
}
