import { useRef, useCallback, useEffect } from 'react';
import { findDOMNode } from 'react-dom';

export function useClickOutside<T extends HTMLElement>(
  onClick: () => void,
  { enabled }: { enabled: boolean } = { enabled: true }
) {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(ref.current);
      if (!node?.contains(event.target as Node)) {
        onClick();
      }
    },
    [onClick]
  );

  useEffect(() => {
    if (enabled) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [enabled, handleClickOutside]);

  return ref;
}
