import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FileGateway } from '../gateways';
import { useNotificationContext } from '../context';
import ArchiveNotification from '../components/notifications/delete';
import { NotificationActionType } from '../reducers/action-types';
import { useRestoreFile } from './';
import { handleResponse } from '@/core/utils/response';
import { entityKeyFactory, fileKeyFactory } from '@/core/query-keys';

interface MutationProps {
  id: string;
}

async function archiveFile({ id }: MutationProps) {
  return handleResponse(FileGateway.archive({ id }));
}

export function useArchiveFile() {
  const queryClient = useQueryClient();
  const restoreMutation = useRestoreFile();
  const { dispatch } = useNotificationContext();

  const archiveNotification = (id: string, name: string) => {
    return (
      <ArchiveNotification
        title="Archived"
        onClose={() => dispatch({ type: NotificationActionType.ClosePanel })}
        onUndo={() => onUndo(id)}
      >
        <p>File {name} was archived successfully.</p>
      </ArchiveNotification>
    );
  };

  const onUndo = (id: string) => {
    restoreMutation.mutate({ id });
    dispatch({ type: NotificationActionType.ClosePanel });
  };

  return useMutation<any, Error, MutationProps, unknown>(archiveFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(fileKeyFactory.stats());
      queryClient.invalidateQueries(entityKeyFactory.all);
    },
    onSettled: ({ id, name }, error) => {
      if (!error) {
        dispatch({
          type: NotificationActionType.OpenPanel,
          payload: { component: archiveNotification(id, name) }
        });
      }
    }
  });
}
