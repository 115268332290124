import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';

import { fetchIntegrations } from '@/core/utils';
import { userKeyFactory } from '@/core/query-keys';
import { Integrations } from '@/core/types';
import { useCurrentUser } from '@/core/hooks';

function integrationEnabled(integrations: Integrations) {
  return get(integrations, 'details.redtailCRM.enabled', false);
}

export function useFetchIntegrations() {
  const { data: user } = useCurrentUser();

  return useQuery(userKeyFactory.integrations, fetchIntegrations, {
    enabled: !!user,
    select: (data) => ({
      ...data,
      canLinkContacts: user
        ? integrationEnabled(data) && user.databaseId === user.currentDatabaseId
        : false
    })
  });
}
